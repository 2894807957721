<template>
  <div class="classify-layout">
    <!-- 第一级 -->
    <ul class="one-ul-layout">
      <li
        class="one-ul-layout-li"
        v-for="(item, index) in dataList"
        :class="{ 'is-show-color': active === index }"
        :key="index"
        @click="onClick(index)"
      >
        <div class="mark" :class="{ 'is-show-mark': active === index }"></div>
        <span>{{ item.class_name }}</span>
      </li>
    </ul>
    <!-- 第二级 -->
    <ul class="two-ul-layout">
      <li
        class="two-ul-li"
        v-for="(item, index) in twoList"
        :key="index"
        :class="{ 'is-show-mark': active === index }"
      >
        <div class="two-ul-title">{{ item.class_name }}</div>
        <!-- 第三级 -->
        <ul class="three-ul-layout">
          <li
            class="three-ul-li"
            v-for="(item, index) in item.child_class"
            :key="index"
            @click="goToGoods(item.id)"
          >
            <div class="sort-img">
              <img :src="item.class_img" alt="" />
            </div>
            <span class="sort-text">{{ item.class_name }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { GoodsClass } from "@/api/goods.js";
export default {
  name: "classify",
  components: {},
  data() {
    return {
      dataList: [],
      twoList: [],
      listTree: [1, 2, 3],
      active: 0,
    };
  },
  mounted() {
    // this.onClick()
    this.getGoodsClass();
  },
  methods: {
    async getGoodsClass() {
      const res = await GoodsClass();
      if (res.code * 1 === 1) {
        this.dataList = res.data;
        this.onClick(0);
      }
    },
    onClick(index = 0) {
      this.active = index;
      this.twoList = this.dataList[index].child_class;
    },
    goToGoods(classId) {
      // 跳转至商品分类列表
      this.$router.push({ name: "goods-list", query: { classId: classId } });
    },
  },
};
</script>

<style lang="less" scoped>
.classify-layout {
  display: flex;
  height: 100vh;
  overflow: auto;
  padding-bottom: 65px;
  box-sizing: border-box;
}
.one-ul-layout {
  width: 110px;
  height: 100%;
  overflow: auto;
  .one-ul-layout-li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    font-size: 16px;
    text-align: center;
    .mark {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      width: 2px;
      height: 22px;
      background-color: #ed301d;
    }
    .is-show-mark {
      display: block;
    }
  }
  .is-show-color {
    color: #ed301d;
  }
}
.two-ul-layout {
  flex: 1;
  overflow: auto;
  .two-ul-li {
    width: 291px;
    margin-right: 12px;
    margin-top: 12px;
    padding: 18px 11px 18px 10px;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: #fff;
    .two-ul-title {
      margin-left: 11px;
      font-weight: 500;
      font-size: 17px;
    }
  }
}
.three-ul-layout {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  .three-ul-li {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 78px;
    margin: 6px 6px 0 6px;
    flex-shrink: 0;
  }
  .sort-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 68px;
    height: 68px;
    // padding: 4px 10px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 10px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .sort-text {
    margin-top: 6px;
    font-size: 13px;
  }
}
</style>
